import React, { FunctionComponent, useState } from 'react'
import axios from 'axios'
import { useLocation, useParams } from 'react-router'
import { GetDataCenterRegionString } from '../../env'
import { useMountEffect } from '../../hooks/MountEffect'
import { HttpStatus } from '../../service/HttpStatus'
import { HelpIcon, RemoteManageIcon } from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import Toaster from '../Toaster'

interface Details {
  serviceProviderName: string
}

export const LegacyOrg: FunctionComponent = () => {
  const [authorized, setAuthorized] = useState(false)
  const [details, setDetails] = useState<Details>()
  const [loading, setLoading] = useState(true)

  const { orgId } = useParams()
  const query = new URLSearchParams(useLocation().search)
  const token = query.get('token') || ''

  const apiPath = '/api/pmitc/orgs/' + orgId

  const addTokenQueryParam = (url: string) => {
    return url + '?token=' + encodeURIComponent(token)
  }

  const loadDetails = async (): Promise<Details> => {
    return axios
      .get(addTokenQueryParam(apiPath))
      .then(response => response.data)
      .catch(err => {
        console.log('failed to load details: ' + err)
        Toaster.notifyFailure('Unable to load service provider details.')
        return Promise.reject(err)
      })
  }

  useMountEffect(() => {
    loadDetails()
      .then(d => {
        setAuthorized(true)
        if (d) {
          setDetails(d)
        }
      })
      .catch(err => {
        if (err.response!.status === HttpStatus.Unauthorized) {
          setAuthorized(false)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  })

  return (
    <div className='add-on'>
      {loading && (
        <div className='empty loading'>
          <LoadingDots />
        </div>
      )}
      {!loading && (
        <div className='flex row'>
          {authorized && (
            <article className='col fp'>
              <div className='embed-container'>
                <iframe src='https://fast.wistia.net/embed/iframe/i05yje69ss' frameBorder='0' title='PaperCut Multiverse add-on info' />
              </div>

              <h4 className='flex flex-ver btm'>
                <i className='icon twenty push'>
                  <RemoteManageIcon />
                </i>

                <span data-heap-redact-text>Your support partner is {details?.serviceProviderName}</span>
              </h4>

              <p>
                They are using the PaperCut Multiverse <b>{GetDataCenterRegionString()}</b> data center.
              </p>

              <p>
                They have access to your usage statistics and performance information so they can catch issues early and support you remotely. You can find out who your friendly
                tech reps are under <b>Administrators</b> on the <b>Settings</b> page.
              </p>

              <div className='pullout alert info'>
                <i className='icon outline twenty'>
                  <HelpIcon />
                </i>

                <div>
                  <h6>Want to remove support partner access?</h6>

                  <p className='no-margin-bottom'>
                    In <b>Settings</b>, find support partner users in the <b>Administrators</b> list and remove them. Then remove this add-on.
                  </p>
                </div>
              </div>
            </article>
          )}
          {!authorized && <div>Unauthorized</div>}
        </div>
      )}
    </div>
  )
}
