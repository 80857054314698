import React, { Dispatch, FunctionComponent, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { LogAccessEvent } from '../../../api/events'
import hiveDashboard from '../../../assets/img/hive-dashboard.png'
import hiveScreenshot from '../../../assets/img/pocket.jpg'
import { defaultAuthHandler } from '../../../auth'
import { setPmitcSession } from '../../../redux/pmitcSession'
import { useTypedSelector } from '../../../redux/reducers'
import { getConnectionStatus, getPmitcSessionLogoutLink, getPmitcSessionName, getPmitcSessionWindow, isDemo } from '../../../redux/selectors'
import AuthRole from '../../../role'
import { HttpStatus } from '../../../service/HttpStatus'
import { ConnectionStatus, HostData, HostDataUtil } from '../../Host/Data'
import { RemoteManageIcon, RemoteWarningIcon } from '../../Icons/Icons'
import PmitcSessionModal from '../../PmitcSessionModal/PmitcSessionModal'
import Toaster from '../../Toaster'

interface Props {
  host: HostData
  teamID: string
}

interface ManageResponse {
  loginLink: string
  logoutLink: string
}

export const manageHiveCustomer = (dispatch: Dispatch<any>, teamId: string, host: HostData, user: string, demoMode: boolean) => {
  if (demoMode) {
    const hiveWindow = window.open(hiveDashboard)

    dispatch(
      setPmitcSession({
        logoutLink: undefined,
        name: 'Demo manage Hive org',
        window: hiveWindow ?? undefined,
      })
    )

    return
  }

  const hiveWindow = window.open()
  axios
    .get<ManageResponse>('/api/teams/' + teamId + '/customers/' + host.id + '/hive/manage')
    .then(result => {
      if (hiveWindow) {
        hiveWindow.location.href = result.data.loginLink

        dispatch(
          setPmitcSession({
            logoutLink: result.data.logoutLink,
            name: host.name,
            window: hiveWindow,
          })
        )

        LogAccessEvent(teamId, host.id, user, HostDataUtil.getProductText(host.product))
      }
    })
    .catch(err => {
      hiveWindow?.close()
      const status = err.response?.status
      if (status === HttpStatus.Locked) {
        Toaster.notifyFailureContent(
          <span>
            Management is not available.
            <br />
            Has this customer added the Partner Remote Assistance add-on?
          </span>
        )
      } else if (status === HttpStatus.Conflict) {
        Toaster.notifyFailureContent(
          <span>
            Unable to manage customer.
            <br />
            This organization already has an administrator with your email address.
          </span>
        )
      } else {
        Toaster.notifyFailure('Unable to manage customer.')
      }
    })
}

export const HiveManage: FunctionComponent<Props> = props => {
  const [showEndPmitcSessionConfirmModal, setShowEndPmitcSessionConfirmModal] = useState(false)
  const dispatch = useDispatch()
  const existingSessionLogoutLink = useTypedSelector(getPmitcSessionLogoutLink)
  const existingSessionName = useTypedSelector(getPmitcSessionName)
  const existingSessionWindow = useTypedSelector(getPmitcSessionWindow)
  const userName = defaultAuthHandler.getUserName()
  const productText = HostDataUtil.getProductText(props.host.product)
  const canManage = AuthRole.hasPermission('remoteManage')
  const connectionDisabled = useTypedSelector(getConnectionStatus(props.host.id)) === ConnectionStatus.Disabled
  const demoMode = useTypedSelector(isDemo)

  const handleManageCustomer = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (existingSessionWindow && !existingSessionWindow.closed) {
      setShowEndPmitcSessionConfirmModal(true)
    } else {
      manageHiveCustomer(dispatch, props.teamID, props.host, userName, demoMode)
    }
  }

  const getManageAnchor = () => {
    let className = ''
    let icon = <RemoteManageIcon />
    let tip = ''
    let smallText = ''
    if (!canManage) {
      className = 'disabled'
      icon = <RemoteWarningIcon />
      tip = 'To gain access, contact your Admin.'
      smallText = 'Not available'
    } else if (connectionDisabled) {
      className = 'disabled'
      icon = <RemoteWarningIcon />
      tip = 'The customer has disabled access.'
      smallText = 'Disabled by customer'
    }

    return (
      <a href='#' className={`btn secondary ${className}`} onClick={handleManageCustomer}>
        <i className='icon'>
          {icon}

          {tip && <span className='tip'>{tip}</span>}
        </i>

        <span>
          Manage
          {smallText && <small>{smallText}</small>}
        </span>
      </a>
    )
  }

  const getManageImage = () => {
    const className = !canManage || connectionDisabled ? 'locked' : ''

    return (
      <figure className={className}>
        <img src={hiveScreenshot} alt={productText} />
      </figure>
    )
  }

  return (
    <div className='remote'>
      <div className='window'>
        {getManageImage()}

        {getManageAnchor()}
      </div>

      {showEndPmitcSessionConfirmModal && (
        <PmitcSessionModal
          customer={existingSessionName}
          onClose={() => setShowEndPmitcSessionConfirmModal(false)}
          onYes={() => {
            if (existingSessionWindow && existingSessionLogoutLink) {
              existingSessionWindow.location.href = existingSessionLogoutLink
            }

            manageHiveCustomer(dispatch, props.teamID, props.host, userName, demoMode)
          }}
        />
      )}
    </div>
  )
}

export default HiveManage
